import { gql } from '@apollo/client';

export const DEPARTMENT_EXIST_QUERY = gql`
  query DepartmentExist($input: DepartmentInput!) {
    department(input: $input) {
      alias
      brandId
      email
      name
    }
  }
`;
