import * as React from 'react';
import styled from 'styled-components';

import { Container } from '../../../layout/Container';
import { Heading } from '../../../ui/text/Heading';
import { Paragraph } from '../../../ui/text/Paragraph';
import { Section } from '../../../layout/Section';
import { BackgroundLoad } from '../../../layout/BackgroundLoad';
import { useFadeTransition } from '../../../../utils/hooks/useFadeTransition';
import { ButtonLink } from '../../../ui/link/ButtonLink';
import { useParams } from 'react-router';
import NoMatch from '../../404Old';
import { useQuery } from '@apollo/client';
import { client } from '../../../../__graphql__/clientCluster';
import { ENV_CONFIG } from '../../../../__config__/env';
import { DEPARTMENT_EXIST_QUERY } from '../../../../../client/__graphql__/cluster/queries/department/exist';
import {
  DepartmentExistQuery,
  DepartmentExistQueryVariables
} from '../../../../__types__/generated/cluster';

const DeliveryDescription: React.FC = () => {
  const params = useParams<{ urltag: string }>();

  const { data, error, loading } = useQuery<
    DepartmentExistQuery,
    DepartmentExistQueryVariables
  >(DEPARTMENT_EXIST_QUERY, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      input: {
        alias: params.urltag,
        brandId: ENV_CONFIG?.BRAND_ID
      }
    }
  });

  const { fadeIn } = useFadeTransition({
    loaded: !loading && data?.department?.alias ? true : false
  });

  if (
    params.urltag === 'hovedkontor' ||
    params.urltag === 'nyeboligerromerike'
  ) {
    return <NoMatch />;
  }

  if (error?.graphQLErrors && error?.graphQLErrors?.length > 0) {
    return <NoMatch />;
  }

  return (
    <Container style={{ paddingTop: '100px', minHeight: '600px' }}>
      {params.urltag === 'nyeboligerbergen' ? (
        <>
          {' '}
          <Section style={fadeIn}>
            <Heading tag="h1" center={true}>
              Leveransebeskrivelse
            </Heading>
            <Paragraph>
              Privatmegleren tilstreber alltid et optimalt eiendomssalg og
              fornøyde kunder. Det er 7 avgjørende faser i et eiendomssalg som
              vil påvirke tid, pris og kundetilfredshet, vi garanterer og
              dokumenterer en optimal leveranse på alle disse punkter. NB! For
              Prosjektoppdrag vil det alltid være behov for skreddersøm
              tilpasset vært enkelt prosjekt. Beskrivelsen nedenfor gjelder salg
              av enkeltstående boliger som ikke er prosjekterte.
            </Paragraph>
            <SquareList>
              <li>Forberedelse til salg m.m. 11,0 timer</li>
              <li>Markedsføring av eiendommen m.m. 11,0 timer</li>
              <li>Visninger m.m. 10,0 timer</li>
              <li>Oppfølging av interessenter m.m. 6,5 timer</li>
              <li>Behandling av bud m.m. 10,5 timer</li>
              <li>Kontrakt m.m. 4,0 timer</li>
              <li>Overtagelse/oppgjør m.m. 6,0 timer</li>
            </SquareList>
            <Heading tag="h3">Forberedelse til salg</Heading>
            <SquareList>
              <li>
                1,0 Forberedelse til befaring, prisundersøkelse, grunnbok m.m.
              </li>
              <li>1,5 Befaring inklusiv kjøring</li>
              <li>Intervju med oppdragsgiver</li>
              <li>1,5 Skriftlig markedsanalyse på din eiendom</li>
              <li>1,0 Registrering av opplysninger i WebMegler</li>
              <li>
                1,0 Bestilling og koordinering av takst, foto, styling,
                håndverkere m.m.
              </li>
              <li>1,0 Koordinering av 3D-plantegning</li>
              <li>
                1,5 Oppfølging av takst, foto, styling, håndverkere, klargjøring
                av eiendom
              </li>
              <li>
                2,0 Innhenting/kontroll av lovpålagte opplysninger og offentlig
                informasjon
              </li>
            </SquareList>
            <Heading tag="h3">Markedsføring</Heading>
            <SquareList>
              <li>
                1,0 Utarbeidelse av DM til utsendelse på eiendommen i nabolaget
              </li>
              <li>
                1,5 Utarbeidelse av nøkkelinformasjon, lovpålagt informasjon,
                eiendommens historikk, områdebeskrivelser og nabolagsprofil.
              </li>
              <li>
                1,5 Bildebehandling, produksjon av prospektpresentasjon,
                vindusplakat
              </li>
              <li>1,0 Utforming av teaserannonser og visningsannonser</li>
              <li>1,0 Utforming av romplakater til visning</li>
              <li>1,0 Korrekturarbeid med oppdragsgiver på markedsmateriell</li>
              <li>1,0 Bestilling og oppfølging av prospekt, annonser og DM.</li>
              <li>
                2,0 Matching mot interessentregister, utsending av brev,
                telefoner til mulige interessenter og invitasjon til private
                visninger.
              </li>
              <li>
                1,0 Opprettelse av egen hjemmeside kun for din eiendom med all
                informasjon/dokumentasjon samt sørge for overføring til Finn.no.
              </li>
            </SquareList>
            <Heading tag="h3">Visning</Heading>
            <SquareList>
              <li>
                8,0 Visninger, kjøring, klargjøring og skilting. Medregnet
                privatvisning og ca 3 fellesvisninger
              </li>
              <li>2,0 Oppfølging av oppdragsgiver rett etter visning</li>
            </SquareList>
            <Heading tag="h3">Oppfølging av interessenter</Heading>
            <SquareList>
              <li>
                4,0 Megler skal første arbeidsdag etter visning kontakte alle
                besøkende og føre logg som kan brukes som statusrapport til
                oppdragsgiver.
              </li>
              <li>
                1,0 Bidrag til å hjelpe interessenter med økonomisk rådgiving og
                finansiering
              </li>
              <li>
                0,5 Skaffe eventuell tilleggsinformasjon til interessenter
              </li>
              <li>1,0 Statusrapporter til oppdragsgiver</li>
            </SquareList>
            <Heading tag="h3">Budbehandling</Heading>
            <Paragraph>
              Megler er tilgjengelig utover normal kontortid for budbehandling
            </Paragraph>
            <SquareList>
              <li>
                Megler er tilgjengelig utover normal kontortid for budbehandling
              </li>
              <li>
                1,0 Mottagelse, registrering og bekreftelse av mottak til
                budgivere
              </li>
              <li>1,0 Kontroll av bud og informasjon fra budgiver</li>
              <li>1,0 Kontroll av budgiveres finansiering</li>
              <li>
                4,0 Budrundeinformasjon, alle interessenter og budgivere
                kontaktes
              </li>
              <li>
                1,0 Løpende informasjon og strategisamtaler med oppdragsgiver
              </li>
              <li>1,0 Akseptavklaringer, overtagelsestidspunkt m.m.</li>
              <li>
                1,0 Føring av detaljert budlogg som oversendes kjøper og selger
                etter aksept
              </li>
              <li>
                0,5 Utarbeidelse og utsendelse av akseptbrev på mail og i post
              </li>
            </SquareList>
            <Heading tag="h3">Kontrakt</Heading>
            <SquareList>
              <li>
                1,0 Kontrakt tilpasses hvert enkel salg med nødvendige
                elementer.
              </li>
              <li>
                0,5 Utsendelse av kontrakt og kontraktsdokumenter til kjøper og
                selger
              </li>
              <li>1,5 Forberedelse og avholdelse av kontraktsmøte</li>
              <li>
                0,5 Klargjøring av oppgjørsoppstilling og regning på meglers
                vederlag og utlegg.
              </li>
              <li>
                0,5 Arkivering og oppbevaring av kontrakt hos megler i 10 år.
              </li>
            </SquareList>
            <Heading tag="h3">Overtagelse/Oppgjør</Heading>
            <SquareList>
              <li>
                1,0 Megler eller områdeekspert fra meglerfirma kan delta på
                selve overtakelsen og fylle ut overtakelsesprotokoll som partene
                signerer.
              </li>
              <li>
                1,0 Tilrettelegging, registrering og kontroll før og etter
                oppgjør
              </li>
              <li>
                2,0 Komplett oppgjør til selger inkl. bekreftelse før
                overtagelse.
              </li>
              <li>
                1,0 Kjøpers oppgjør inkl. tinglysing av dokumenter. Skjøtet
                sendes til kjøper når alt er klart
              </li>
              <li>
                1,0 Oppfølging, brev, inneståelser, arkivering og rapportering.
              </li>
            </SquareList>
            <Heading tag="h3">Prisliste</Heading>
            <ButtonLink as="link" to={`/${params.urltag}/prisliste`}>
              Prisliste (åpnes i nytt vindu)
            </ButtonLink>
          </Section>
        </>
      ) : (
        <>
          {' '}
          <Section style={fadeIn}>
            <Heading tag="h1" center={true}>
              Leveransebeskrivelse
            </Heading>
            <Paragraph>
              Privatmegleren tilstreber alltid et optimalt eiendomssalg og
              fornøyde kunder. Det er 7 avgjørende faser i et eiendomssalg som
              vil påvirke tid, pris og kundetilfredshet, vi garanterer og
              dokumenterer en optimal leveranse på alle disse punkter
            </Paragraph>
            <SquareList>
              <li>Forberedelse til salg m.m. 11,0 timer</li>
              <li>Markedsføring av eiendommen m.m. 11,0 timer</li>
              <li>Visninger m.m. 10,0 timer</li>
              <li>Oppfølging av interessenter m.m. 6,5 timer</li>
              <li>Behandling av bud m.m. 10,5 timer</li>
              <li>Kontrakt m.m. 4,0 timer</li>
              <li>Overtagelse/oppgjør m.m. 6,0 timer</li>
            </SquareList>
            <Heading tag="h3">Forberedelse til salg</Heading>
            <SquareList>
              <li>
                1,0 Forberedelse til befaring, prisundersøkelse, grunnbok m.m.
              </li>
              <li>1,5 Befaring inklusiv kjøring</li>
              <li>0,5 Intervju med oppdragsgiver</li>
              <li>1,5 Skriftlig markedsanalyse på din eiendom</li>
              <li>1,0 Registrering av opplysninger i WebMegler</li>
              <li>
                1,0 Bestilling og koordinering av takst, foto, styling,
                håndverkere m.m.
              </li>
              <li>1,0 Koordinering av 3D-plantegning</li>
              <li>
                1,5 Oppfølging av takst, foto, styling, håndverkere, klargjøring
                av eiendom
              </li>
              <li>
                2,0 Innhenting/kontroll av lovpålagte opplysninger og offentlig
                informasjon
              </li>
            </SquareList>
            <Heading tag="h3">Markedsføring</Heading>
            <SquareList>
              <li>
                1,0 Utarbeidelse av DM til utsendelse på eiendommen i nabolaget
              </li>
              <li>
                1,5 Utarbeidelse av nøkkelinformasjon, lovpålagt informasjon,
                eiendommens historikk, områdebeskrivelser og nabolagsprofil.
              </li>
              <li>
                1,5 Bildebehandling, produksjon av prospektpresentasjon,
                vindusplakat
              </li>
              <li>1,0 Utforming av teaserannonser og visningsannonser</li>
              <li>1,0 Utforming av romplakater til visning</li>
              <li>1,0 Korrekturarbeid med oppdragsgiver på markedsmateriell</li>
              <li>1,0 Bestilling og oppfølging av prospekt, annonser og DM.</li>
              <li>
                2,0 Matching mot interessentregister, utsending av brev,
                telefoner til mulige interessenter og invitasjon til private
                visninger.
              </li>
              <li>
                1,0 Opprettelse av egen hjemmeside kun for din eiendom med all
                informasjon/dokumentasjon samt sørge for overføring til Finn.no.
              </li>
            </SquareList>
            <Heading tag="h3">Visning</Heading>
            <SquareList>
              <li>
                8,0 Visninger, kjøring, klargjøring og skilting. Medregnet
                privatvisning og ca 3 fellesvisninger
              </li>
              <li>2,0 Oppfølging av oppdragsgiver rett etter visning</li>
            </SquareList>
            <Heading tag="h3">Oppfølging av interessenter</Heading>
            <SquareList>
              <li>
                4,0 Megler skal første arbeidsdag etter visning kontakte alle
                besøkende og føre logg som kan brukes som statusrapport til
                oppdragsgiver.
              </li>
              <li>
                1,0 Bidrag til å hjelpe interessenter med økonomisk rådgiving og
                finansiering
              </li>
              <li>
                0,5 Skaffe eventuell tilleggsinformasjon til interessenter
              </li>
              <li>1,0 Statusrapporter til oppdragsgiver</li>
            </SquareList>
            <Heading tag="h3">Budbehandling</Heading>
            <SquareList>
              <li>
                Megler er tilgjengelig utover normal kontortid for budbehandling
              </li>
              <li>
                1,0 Mottagelse, registrering og bekreftelse av mottak til
                budgivere
              </li>
              <li>1,0 Kontroll av bud og informasjon fra budgiver</li>
              <li>1,0 Kontroll av budgiveres finansiering</li>
              <li>
                4,0 Budrundeinformasjon, alle interessenter og budgivere
                kontaktes
              </li>
              <li>
                1,0 Løpende informasjon og strategisamtaler med oppdragsgiver
              </li>
              <li>1,0 Akseptavklaringer, overtagelsestidspunkt m.m.</li>
              <li>
                1,0 Føring av detaljert budlogg som oversendes kjøper og selger
                etter aksept
              </li>
              <li>
                0,5 Utarbeidelse og utsendelse av akseptbrev på mail og i post
              </li>
            </SquareList>
            <Heading tag="h3">Kontrakt</Heading>
            <SquareList>
              <li>
                1,0 Kontrakt tilpasses hvert enkel salg med nødvendige
                elementer.
              </li>
              <li>
                0,5 Utsendelse av kontrakt og kontraktsdokumenter til kjøper og
                selger
              </li>
              <li>1,5 Forberedelse og avholdelse av kontraktsmøte</li>
              <li>
                0,5 Klargjøring av oppgjørsoppstilling og regning på meglers
                vederlag og utlegg.
              </li>
              <li>
                0,5 Arkivering og oppbevaring av kontrakt hos megler i 10 år.
              </li>
            </SquareList>
            <Heading tag="h3">Overtagelse/Oppgjør</Heading>
            <SquareList>
              <li>
                1,0 Megler eller områdeekspert fra meglerfirma kan delta på
                selve overtakelsen og fylle ut overtakelsesprotokoll som partene
                signerer.
              </li>
              <li>
                1,0 Tilrettelegging, registrering og kontroll før og etter
                oppgjør
              </li>
              <li>
                2,0 Komplett oppgjør til selger inkl. bekreftelse før
                overtagelse.
              </li>
              <li>
                1,0 Kjøpers oppgjør inkl. tinglysing av dokumenter. Skjøtet
                sendes til kjøper når alt er klart
              </li>
              <li>
                1,0 Oppfølging, brev, inneståelser, arkivering og rapportering.
              </li>
            </SquareList>
            <Heading tag="h3">Prisliste</Heading>
            <ButtonLink as="link" to={`/${params.urltag}/prisliste`}>
              Prisliste (åpnes i nytt vindu)
            </ButtonLink>
          </Section>
        </>
      )}

      <BackgroundLoad
        opacity={0.2}
        fixed={true}
        position="center"
        url="https://cdn.reeltime.no/pm_assets/img/bakgrunner/falk_bakside2.jpg"
      />
    </Container>
  );
};

export default DeliveryDescription;

const SquareList = styled.ul`
  list-style: square;
  color: white;
`;
